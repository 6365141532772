
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('bootstrap-select/js/bootstrap-select.js')
require('@fortawesome/fontawesome-free/js/all.js');

window.Vue = require('vue');
window.moment = require('moment');
window.moment.locale('fr');

import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
import moment from 'moment'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.component('reception-component', require('./components/ReceptionComponent.vue').default);
Vue.component('recherche-chantier-component', require('./components/RechercheChantierComponent.vue').default);
Vue.component('email-client-component', require('./components/EmailClientComponent.vue').default);
Vue.component('materiel-chantier', require('./components/MaterielChantierComponent.vue').default);
Vue.component('personnel', require('./components/PersonnelComponent.vue').default);

const app = new Vue({
    el: '#app'
});
