<template>
    <div>
        <div class="d-flex justify-content-between w-100">
            <div>
                <h2>Liste du personel <span class="badge">{{this.dataPersonnels.length}}</span></h2>
            </div>
            <div>
                <h2><a class="float-right" data-bs-toggle="modal" data-bs-target="#modalAddPersonnel"><i
                        class="fa fa-plus"></i></a></h2>
            </div>
        </div>
        <validation-error :errors="validationErrors" v-if="validationErrors"></validation-error>
        <div class="table-responsive">
            <table class="table table-striped table-condensed table-sm">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prénom</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(personnel, index) in dataPersonnels">
                        <td>{{ personnel.nom}}</td>
                        <td>{{ personnel.prenom}}</td>
                        <td>
                            <form @submit.prevent="deletePersonnel(index)">
                                <button type="submit" class="btn btn-danger btn-sm">Supprimer</button>
                            </form>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="modal" tabindex="-1" role="dialog" id="modalAddPersonnel">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Ajouter personnel</h4>
                    </div>
                    <div class="modal-body" @submit.prevent="addPersonnel()">
                        <form>
                            <div class="form-group">
                                <label>Prénom:</label>
                                <input class="form-control" name="prenom" v-model="prenom">
                            </div>
                            <div class="form-group">
                                <label>Nom:</label>
                                <input class="form-control" name="prenom" v-model="nom">
                            </div>
                            <div class="d-flex justify-content-center m-3">
                                <button type="submit" class="btn btn-primary">Ajouter</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ValidationError from "./ValidationError";

    export default {
        components: {ValidationError},
        props: {
            personnels: Array,
            type_erreurs: Array
        },
        data() {
            return {
                dataPersonnels: this.personnels,
                validationErrors: null,
                prenom: null,
                nom: null,
            }
        },
        methods: {
            deletePersonnel: function (index) {
                axios.post('/personnel/delete', {
                    'id': this.dataPersonnels[index].id
                }).then((result) => {
                    console.log(result);
                    this.dataPersonnels = result.data.data
                }).catch((error) => {
                    console.log(error.response)
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                        console.log(error.data)
                    }
                })
            },
            addPersonnel: function() {
                axios.post('/personnel/create', {
                    'prenom': this.prenom,
                    'nom': this.nom,
                }).then((result) => {
                    console.log(result);
                    this.dataPersonnels = result.data.data;
                    $('#modalAddPersonnel').modal('hide');
                }).catch((error) => {
                    console.log(error.response)
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                        console.log(error.data)
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>