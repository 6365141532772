<template>
    <div class="container-fluid d-flex justify-content-center">
        <div class="col-10">
            <form @submit.prevent="ajoutArticle()">
                <div class="form-group">
                    <label for="familleArticle">Famille article</label>
                    <select class="form-select" id="familleArticle" v-model="famille_select">
                        <option v-for="famille in Familles" :value="famille.id">{{ famille.libelle }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="article">Article</label>
                    <select class="form-select" id="article" v-model="article_select">
                        <option v-for="article in Articles" v-if="article.famille_article_id == famille_select"
                                :value="article.id">{{ article.libelle }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <div><b>Quantité</b></div>
                    <input class="form-control" min="1" max="1000" id="quantite" type="number" v-model="quantite_select">
                </div>
                <div class="d-flex justify-content-center m-3">
                    <button type="submit" class="btn btn-primary"><i class="fas fa-plus"></i></button>
                </div>

            </form>

            <div class="row">
                <table class="table" style="margin-top: 40px ">
                    <thead>
                    <tr>
                        <th scope="col">Famille</th>
                        <th scope="col">Article</th>
                        <th scope="col">Quantité</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(materiel,index) in Materiels">
                        <td>
                            <span>{{ materiel.famille_article.libelle }}</span>
                        </td>
                        <td>{{ materiel.libelle }}</td>
                        <td class="col-xs-3">

                            <input class="form-control" :id="index" type="number" min="1" max="1000" v-model="materiel.pivot.quantite"
                                   :placeholder="materiel.pivot.quantite" @change="updateQuantite(materiel)">

                        </td>
                        <td class="col-xs-1">
                            <form @submit.prevent="supp(materiel, index)">
                                <button type="submit" class="btn btn-danger"><i class="fas fa-trash"></i></button>
                            </form>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: ['familles', 'articles', 'articleschantiers', 'id'],
    data() {
        return {
            Familles: this.familles,
            Articles: this.articles,
            Materiels: this.articleschantiers,
            Id: this.id,
            famille_select: '',
            article_select: '',
            quantite_select: '',
            quantite_modif: ''
        }
    },
    methods: {
        ajoutArticle: function () {
            axios.post('/materielChantier/add',
                {
                    'famille': this.famille_select,
                    'article': this.article_select,
                    'quantite': this.quantite_select,
                    'id': this.Id,
                }).then(response => {
                this.Materiels = response.data.data
            })
        },
        supp: function (materiel, index) {
            axios.post('/materielChantier/supp',
                {
                    'id': materiel.pivot.id,
                })
            this.Materiels.splice(index, 1)
        },

        modif: function (materiel, index) {
            axios.post('/materielChantier/modif',
                {
                    'id': materiel.id,
                    'quantite': materiel.quantite,
                }).then(response => (

                this.Materiels[index].quantite = response.data.quantite
            ))
        },
        updateQuantite: function (materiel) {
            axios.post('/materielChantier/modif',
                {
                    'id': materiel.pivot.id,
                    'quantite': materiel.pivot.quantite,
                }).then(response => (

                this.Materiels[index].quantite = response.data.quantite
            ))
        },
    },
    mounted() {
    }

}
</script>
