<template>
    <div class="col-10">
        <div class="table-responsive" v-if="emails">
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Client</th>
                    <th>#</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="email in emails">
                    <td>{{ email.email }}</td>
                    <td>{{ email.client.raison_sociale }}</td>
                    <td>
                        <form method="post" action="#" @submit.prevent="supprimeEmail(email)">
                            <button type="submit" class="btn btn-danger">Supprimer</button>
                        </form>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center">
            <validation-error :errors="validationErrors" v-if="validationErrors"></validation-error>
        </div>

        <div class="col-10">
            <form class="form" method="post" action="#" @submit.prevent="ajoutEmail()">
                <fieldset>

                    <!-- Form Name -->
                    <legend>Ajouter une adresse Email</legend>
                    <div class="d-flex justify-content-between">
                        <!-- Text input-->
                        <div class="form-group col-8">
                            <div>
                                <input v-model="form_email" id="email" name="email" class="form-control input-md"
                                       required="" type="text">
                            </div>
                        </div>

                        <div class="form-group">
                            <div>

                            </div>
                            <div>
                                <button type="submit" class="btn btn-primary pull-right">Ajouter l'adresse E-Mail
                                </button>
                            </div>
                        </div>
                    </div>

                </fieldset>
            </form>
        </div>
    </div>
</template>

<script>
import ValidationError from "./ValidationError";

export default {
    components: {ValidationError},
    props: {
        clients: Array,
        type_erreurs: Array,
        emails: Array,
        client: Object,
        form_email: String,
    },
    data() {
        return {
            dataClients: this.clients,
            dataTypeErreurs: this.type_erreurs,
            dateEmails: this.emails,
            dataClient: this.client,
            validationErrors: null,
        }
    },
    methods: {
        ajoutEmail: function (data) {
            axios.post('/client/edit_email/' + this.dataClient.id, {
                email: this.form_email
            }).then((result) => {
                this.emails = result.data.data
            }).catch((error) => {
                console.log(error.response)
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                    console.log(error.data)
                }
            })
        },
        supprimeEmail: function (email) {
            axios.post('/client/delete_email/' + this.dataClient.id, {
                email: email.id
            }).then((result) => {
                this.emails = result.data.data
            }).catch((error) => {
                console.log(error.response)
                if (error.response.status == 422) {
                    this.validationErrors = error.response.data.errors;
                    console.log(error.data)
                }
            })
        }
    }
}
</script>

<style scoped>

</style>