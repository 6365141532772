<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h2>Réception du colis</h2>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <validation-error :errors="validationErrors" v-if="validationErrors"></validation-error>
                <form class="form-inline" @submit.prevent="valideCodeBarre(code_barre)">
                    <input type="hidden" name="_token" :value="csrf">
                    <div class="form-group">
                        <label for="code_barre">Code barre:</label>
                        <input type="text" class="form-control input-lg" id="code_barre" v-model="code_barre">
                    </div>
                    <button type="submit" class="btn btn-default btn-lg">Valider</button>
                </form>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-md-offset-2 col-md-8">
                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4>Référence:</h4>
                            </div>
                            <div class="panel-body">
                                <div class="row">
                                    <div class="col-xs-4"><span class="pull-right">Client:</span></div>
                                    <div class="col-xs-4" v-if="chantier && chantier.client">
                                        {{this.chantier.client.raison_sociale}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-4"><span class="pull-right">Nombre de colis:</span></div>
                                    <div class="col-xs-4" v-if="chantier!=null">
                                        {{this.chantier.nombre_colis}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xs-4"><span class="pull-right">Expédié le:</span></div>
                                    <div class="col-xs-4" v-if="chantier && chantier.date_expedition">{{chantier.date_expedition
                                        | moment}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row equal" v-if="chantier!=null">
                    <div class="col-md-6">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h4>Colis incomplet</h4>
                            </div>
                            <div class="panel-body">
                                <form @submit.prevent="valideIncomplet(chantier)">
                                    <div class="form-group">
                                        <label>Observation livraison:</label>
                                        <textarea class="form-control" name="observation_livraison"
                                                  v-model="chantier.observation_livraison"></textarea>
                                    </div>
                                    <button class="btn btn-danger" type="submit">Valider</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="panel panel-default">
                            <form @submit.prevent="valideComplet(chantier)">
                                <div class="panel-heading">
                                    <h4>Colis complet</h4>
                                </div>
                                <div class="panel-body">
                                    <label>Colis reçu complet</label>
                                    <button class="btn btn-success" type="submit">Valider</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ValidationError from "./ValidationError";

    export default {
        components: {ValidationError},
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                chantier: null,
                code_barre: "",
                validationErrors: null,
            }
        },
        methods: {
            valideCodeBarre: function (data) {
                console.log(data);
                axios.post('/chantier/show/codebarre', {
                    code_barre: data,
                }).then((result) => {
                    this.chantier = result.data.data
                    this.validationErrors = null
                }).catch((error) => {
                    console.log(error.response)
                    if (error.response.status == 422) {
                        this.validationErrors = error.response.data.errors;
                        console.log(error.data)
                    }
                })
            },
            valideIncomplet: function (data) {
                console.log(data)
                axios.post('/chantier/reception', {
                    id: this.chantier.id,
                    statut: 'incomplet',
                    observation_livraison: this.chantier.observation_livraison,
                }).then((result) => {
                    this.chantier = null
                    this.$alert("Colis validé avec succès");
                })
            },
            valideComplet: function (data) {
                console.log(data)
                axios.post('/chantier/reception', {
                    id: this.chantier.id,
                    statut: 'complet',
                    observation_livraison: ''
                }).then((result) => {
                    this.chantier = null
                    this.$alert("Colis validé avec succès");
                })
            }
        },
        filters: {
            moment: function (date) {
                return moment(date).format('D/M/Y H:m');
            }
        },
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
