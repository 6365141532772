<template>
    <div class="container-fluid d-flex justify-content-center">
        <div class="col-8">
            <div class="text-center">
                <h2>Recherche des chantiers</h2>
                <hr>
            </div>

            <div class="text-center">
                <validation-error :errors="validationErrors" v-if="validationErrors"></validation-error>
            </div>
            <form @submit.prevent="rechercheChantier()" action="/chantiers/recherche" class="row">
                <div class="col-5">
                    <div>
                        <div class="form-group">
                            <label for="date_debut">Date début</label>
                            <input type="date" class="form-control" id="date_debut" v-model="date_debut">
                        </div>
                        <div class="form-group">
                            <label for="date_fin">Date fin</label>
                            <input type="date" class="form-control" id="date_fin" v-model="date_fin">
                        </div>
                    </div>

                    <div>
                        <div class="form-group">
                            <label for="date_debut_livraison">Date début livraison souhaitée</label>
                            <input type="date" class="form-control" id="date_debut_livraison"
                                   v-model="date_debut_livraison">
                        </div>
                        <div class="form-group">
                            <label for="date_fin_livraison">Date fin livraison souhaitée</label>
                            <input type="date" class="form-control" id="date_fin_livraison" v-model="date_fin_livraison">
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div>
                        <div class="form-group">
                            <label style="display: block;">Clients</label>
                            <select multiple name="client" class="form-select" v-model="clients_selected">
                                <option v-for="client in dataClients" v-bind:value="client.id">
                                    {{ client.raison_sociale }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label style="display: block;">Type Erreur</label>
                            <select multiple name="type_erreur" class="form-select" v-model="type_erreurs_selected">
                                <option v-for="type_erreur in dataTypeErreurs" v-bind:value="type_erreur.id">
                                    {{ type_erreur.libelle }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column justify-content-center col-2">
                    <div class="m-3">
                        <button class="btn btn-success" type="button" @click="exportChantier()">Export excel</button>
                    </div>
                    <div class="m-3">
                        <button class="btn btn-primary" type="submit">Rechercher</button>
                    </div>
                </div>



            </form>
            <div class="row">
                <div class="table-responsive" v-if="chantiers">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Référence</th>
                            <th>Client</th>
                            <th>Commandée le</th>
                            <th>Livraison souhaitee</th>
                            <th>Statut</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="chantier in chantiers">
                            <td>{{ chantier.id }}</td>
                            <td>{{ chantier.reference }}</td>
                            <td><span v-if="chantier.client">{{ chantier.client.raison_sociale }}</span></td>
                            <td>{{ chantier.commande_le | moment }}</td>
                            <td>{{ chantier.date_livraison_souhaitee | moment }}</td>
                            <td><span v-if="chantier.statut" class="label"
                                      :class="'label-'+chantier.statut.couleur">{{ chantier.statut.libelle }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ValidationError from "./ValidationError";

export default {
    components: {ValidationError},
    props: {
        clients: Array,
        type_erreurs: Array,
    },
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            validationErrors: null,
            dataClients: this.clients,
            dataTypeErreurs: this.type_erreurs,
            date_debut: null,
            date_debut_livraison: null,
            date_fin: null,
            date_fin_livraison: null,
            clients_selected: [],
            type_erreurs_selected: [],
            chantiers: null,
        }
    },
    methods: {
        rechercheChantier: function (data) {
            axios.post('/chantiers/recherche', {
                date_debut: this.date_debut,
                date_debut_livraison: this.date_debut_livraison,
                date_fin: this.date_fin,
                date_fin_livraison: this.date_fin_livraison,
                clients: this.clients_selected,
                type_erreurs: this.type_erreurs_selected,
            }).then((result) => {
                this.chantiers = result.data.data
            })
        },
        exportChantier: function (data) {
            axios.post('/chantiers/export', {
                date_debut: this.date_debut,
                date_debut_livraison: this.date_debut_livraison,
                date_fin: this.date_fin,
                date_fin_livraison: this.date_fin_livraison,
                clients: this.clients_selected,
                type_erreurs: this.type_erreurs_selected,
            }, {
                responseType: 'blob'
            }).then((result) => {
                //this.chantiers = result.data.data
                //console.log(result.data)
                var fileURL = window.URL.createObjectURL(new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                }));
                var fileLink = document.createElement('a');


                fileLink.href = fileURL;

                fileLink.setAttribute('download', 'Chantiers.xlsx');

                document.body.appendChild(fileLink);


                fileLink.click();
            })
        }
    },
    filters: {
        moment: function (date) {
            if (date != null)
                return moment(date).format('DD/MM/Y')
            else
                return ""
        }
    },
    mounted() {
        console.log("test");
    }
}
</script>
